import React, { useState } from "react";
import "../styles/Header.css"; // Assuming you're using the correct path
import logo from "../assets/QWAT.png"; // Update the path if necessary

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle Sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Close Sidebar
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="https://qwat.in" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Qwat Logo" />
        </a>
      </div>
      <nav className="desktop-nav">
        <ul className="nav-links">
          <li>
            <a href="https://portfolio.qwat.in" target="_blank" rel="noopener noreferrer">
              Portfolio
            </a>

            <a href="https://blog.qwat.in" target="_blank" rel="noopener noreferrer">
              Our Blogs
            </a>
          </li>
        </ul>
      </nav>
      <a 
        href="https://wa.me/919080870732" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="contact-btn desktop-contact"
      >
        LET'S TALK
      </a>

      {/* Hamburger for Mobile */}
      <div className="hamburger" onClick={toggleSidebar}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* Sidebar for Mobile */}
      {isSidebarOpen && (
        <div className="sidebar">
          {/* Sidebar Logo */}
          <div className="sidebar-logo">
            <a href="https://qwat.in" target="_blank" rel="noopener noreferrer">
              <img src={logo} alt="Qwat Logo" />
            </a>
            <span className="close-icon" onClick={closeSidebar}>
            ✕
          </span>
          </div>

          <ul className="sidebar-links">
            <li>
              <a href="https://portfolio.qwat.in" target="_blank" rel="noopener noreferrer" onClick={closeSidebar}>
                Portfolio
              </a>
            </li>
            <li>
              <a href="https://blog.qwat.in" target="_blank" rel="noopener noreferrer" onClick={closeSidebar}>
                Our Blogs
              </a>
            </li>
            <li>
              <a 
                href="https://wa.me/919080870732" 
                target="_blank" 
                rel="noopener noreferrer" 
                onClick={closeSidebar}
                className="mobile-contact"
              >
                LET'S TALK
              </a>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
